<template>
    <div class="main" id="emailsHeader">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <h1 class="row">Unrecognised e-mails</h1>
                        </div>
                        <div class="col-6">
                            <div class="row" style="justify-content: flex-end;">
                                <router-link class="btn" to="/dispute/employee/group/show" active-class="active">dispute overview</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="col-12">
                    <form class="card row">
                        <div class="col-12">
                            <div class="card-header row">filters</div>
                            <div class="cord-body row" style="padding: 15px 0">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="input-group">
                                                <label class="custom-control custom-checkbox">
                                                    <input type="radio" name="a" class="custom-control-input" :value="false" v-model="filters.isUnread" @change="getEmails">
                                                    <span class="custom-control-indicator" style="top: .2rem;"></span>
                                                    <span style="vertical-align: middle;">All</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="input-group">
                                                <label class="custom-control custom-checkbox">
                                                    <input type="radio" name="a" class="custom-control-input" :value="true" v-model="filters.isUnread" @change="getEmails">
                                                    <span class="custom-control-indicator" style="top: .2rem;"></span>
                                                    <span style="vertical-align: middle;">Unread</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="col-12">
                    <div class="row">
                    <table class="table content-card" style="margin-top: 40px;">
                        <thead>
                        <tr>
                            <th width="15%">
                                van
                            </th>
                            <th width="40%">
                                subject
                            </th>
                            <th class="text-center" width="45%" colspan="3">

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="email in emails"
                        >
                            <td @click.prevent="openEmailPopup(email)">
                                {{ email.emailFrom }}
                            </td>
                            <td @click.prevent="openEmailPopup(email)">
                                {{ email.subject }}
                            </td>
                            <td class="text-center">
                                <a href="" @click.prevent="markAsRead(email)" class="btn btn-sm" :class="!email.unread ? 'hidden' : ''">mark as read</a>
                            </td>
                            <td class="text-center">
                                <a href="" @click.prevent="deleteEmail(email)" class="btn btn-sm">delete email</a>
                            </td>
                            <td>
                                <a href="" @click.prevent="findDispute(email)" class="btn btn-sm">find dispute</a>
                                <div class="imgMes">
                                    <b-modal :ref="`mailModal-${email.id}`" size="lg" hide-footer :title="email.templateName">
                                        <iframe :id="`iframe-${email.id}`" frameborder="0" style="width: 100%; height: 70vh"></iframe>
                                    </b-modal>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <b-pagination
                v-if="pagData.maxEmails > 50"
                class="green"
                align="center"
                prev-text="Previous"
                next-text="Next"
                :limit="11"
                @input="goToPage"
                :total-rows="pagData.maxEmails"
                v-model="pagData.currentPage"
                :per-page="50"
            />
        </div>
        <DisputeFilterModal ref="disputeFilter" @coupleToEmail="coupleToEmail" />
    </div>
</template>

<script>
	import DisputeFilterModal from '@/components/disputeView/modals/DisputeFilterModal.vue'

	export default {
		name: "UnrecognisedMails",
        data() {
			return {
				emails: [],
                filters: {
	                isUnread: false
                },
				pagData: {
					currentPage: 1,
					maxEmails: 0,
				},
            }
        },
        mounted() {
            this.getEmails()
        },
        methods: {
			goToPage(page) {
				this.pagData.currentPage = page
                this.getEmails()
            },
	        coupleToEmail(coupleData) {
		        disputeAxios
			        .post('/api/email/manual-import/couple', coupleData)
			        .then(({data}) => {
				       if (data.code === 2000) {
				       	    let email = this.emails.find(item => item.id === coupleData.messageId),
                                index = this.emails.indexOf(email)

                            this.emails.splice(index, 1)
					       EventBus.$emit('showAlertModal', data.message)
                       }
			        })
			        .catch(err => console.error(err))
            },
	        findDispute(email) {
		        this.$refs.disputeFilter.show(email)
	        },
			getEmails() {
				let params = {
					pageNumber: this.pagData.currentPage
                }

				if (this.filters.isUnread) { params.isUnread = true }

				setTimeout(() => Helper.scrollTo('emailsHeader', 'start'), 0)
				disputeAxios
                    .get('/api/email/manual-import/list', { params })
                    .then(({data}) => {
                    	this.emails = data.emails

                        this.pagData.maxEmails = data.maxPageNumber * 50
                    })
                    .catch(err => console.error(err))
            },
	        openEmailPopup({id}) {
		        disputeAxios
			        .get(`/api/email/manual-import/show/${id}`)
			        .then(({data}) => {
                        this.$refs[`mailModal-${id}`][0].show()

				        setTimeout(() => Helper.fillEmailIframe(`iframe-${id}`, data.content), 100)
			        })
			        .catch(err => console.error(err))
	        },
	        markAsRead(email) {
	        	if (email.unread) {
			        disputeAxios
				        .post(`/api/email/manual-import/mark-as-read/${email.id}`)
				        .then(({data}) => {
					        this.getEmails()
				        })
				        .catch(err => console.error(err))
                }
            },
            deleteEmail(email) {
	            disputeAxios
		            .delete(`/api/email/manual-import/remove/${email.id}`)
		            .then(({data}) => {
			            if (data.code === 2000) {
                            this.emails.splice(this.emails.indexOf(email), 1)
			            } else {
				            EventBus.$emit('showAlertModal', 'Something went wrong :(')
			            }
		            })
		            .catch(err => console.error(err))
            }
        },
		components: {
			DisputeFilterModal
		},
	}
</script>

<style scoped lang="scss">
    td {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    label {
        cursor: pointer;
    }
    .hidden {
        opacity: 0;
        pointer-events: none;
    }
    .table {
        table-layout: fixed;
    }
    .content-card {
        .imgMes .modal-header {
            background: #032438;
        }
        .imgMes .modal-header, .modal-title,
        .imgMes .modal-header .close{
            color: #ffffff;
        }
    }
    .pag {
        >div {
            padding: 0;
        }
        &-nums {
            a {
                display: inline-block;
                padding: .5rem .75rem;
                line-height: 1.25;
                background-color: #fff;
                border: 1px solid #dee2e6;
                &:hover {
                    background-color: #ccf0ef;
                    border-color: #4db5af;
                }
                &.active {
                    background-color: #4db5af;
                    color: #ffffff;
                    border-color: #4db5af;
                }
            }
        }
        &-arrows {
            text-align: right;
            i {
                color: #4db5af;
                font-size: 32px;
                line-height: 36px;
                margin-left: 10px;
            }
        }
    }
</style>
<template>
    <b-modal ref="disputeFilterModal" @hidden="clear" hide-footer title="Dispute Filter" size="lg">
        <form class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <div class="row" style="padding: 15px 0">
                    <div class="col-6">
                        <div class="">
                            <label for="">Email</label>
                            <v-autocomplete
                                :min-len="3"
                                v-model="autocompletePickMail"
                                :items="autocompleteDataMail"
                                :get-label="getLabel"
                                :component-item='itemTemplate'
                                @update-items="getFilteredAutocomplete($event, 'email')"
                                @item-selected="autocompleteDataIsSelected($event, 'email')"
                                @change="checkIfAutocompleteIsEmpty($event)"
                            ></v-autocomplete>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="">
                            <label for="">Invoice</label>
                            <v-autocomplete
                                :min-len="3"
                                v-model="autocompletePickInv"
                                :items="autocompleteDataInv"
                                :get-label="getLabel"
                                :component-item='itemTemplate'
                                @update-items="getFilteredAutocomplete($event, 'invoice')"
                                @item-selected="autocompleteDataIsSelected($event, 'invoice')"
                                @change="checkIfAutocompleteIsEmpty($event)"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row" style="margin-top: 30px;" v-if="disputes.length">
            <div class="col-12">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Reason</th>
                        <th>Customer Email</th>
                        <th>Client Email</th>
                        <th>Invoice</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="dispute in disputes">
                        <td @click="goToDisputePage(dispute)">{{ dispute.lastDisputeReason }}</td>
                        <td @click="goToDisputePage(dispute)">{{ dispute.customerEmail }}</td>
                        <td @click="goToDisputePage(dispute)">{{ dispute.clientEmail }}</td>
                        <td @click="goToDisputePage(dispute)">{{ dispute.invoice }}</td>
                        <td>
                            <a href="" @click.prevent="coupleToEmail(dispute)" class="btn">Couple</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>
</template>

<script>
	import ClientItemTemplate from '@/components/disputeView/DisputeAutocompleteItem.vue'

	export default {
		name: "DisputeFilterModal",
        data() {
			return {
				disputes: [],
				id: '',
				autocompleteDataInv: [],
				autocompletePickInv: null,
				autocompleteDataMail: [],
				autocompletePickMail: null,
				itemTemplate: ClientItemTemplate,
            }
        },
        methods: {
	        goToDisputePage({disputeId, groupId}) {
		        window.open(`/dispute/employee/show/${groupId}/details/${disputeId}`, '_blank') // if doesnt work generate <a>
            },
	        coupleToEmail(dispute) {
	        	this.$emit('coupleToEmail', { disputeId: dispute.disputeId , messageId: this.id} )
                this.$refs.disputeFilterModal.hide()
                this.clear()
            },
			show({id}) {
	        	if (id) {
                    this.id = id
			        this.$refs.disputeFilterModal.show()
                }
            },
	        clear() {
		        this.disputes = []
                this.id = ''
		        this.autocompleteDataInv = []
		        this.autocompletePickInv = null
		        this.autocompleteDataMail = []
		        this.autocompletePickMail = null
            },
            checkIfAutocompleteIsEmpty(val) {
                if (val.length === 0) { this.disputes = [] }
            },
            getFilteredAutocomplete(val, type) {
                let tempVal = val

                if (type === 'invoice') {tempVal = val}  // govnocod

                let data = {}
                data[type] = tempVal

                disputeAxios
                    .post(`/api/employee-dispute/groups-autocomplete/check`, data)
                    .then(({data}) => {
                        if (type === 'invoice') {
                            this.autocompleteDataMail = []
                            this.autocompletePickMail = null
                            this.autocompleteDataInv = data[type]
                        } else {
                            this.autocompleteDataInv = []
                            this.autocompletePickInv = null
                            this.autocompleteDataMail = data[type]
                        }
                    })
                    .catch(err => console.error(err))
            },
            autocompleteDataIsSelected(val, type) {
                disputeAxios
                    .post(`/api/employee-dispute/groups-autocomplete/confirm`, val)
                    .then(({data}) => {
                    	this.disputes = data.filter((item) => !item.resolved)
                    })
                    .catch(err => console.error(err))
            },
            getLabel (item) {
                return !!item ? item.email || item.invoice : ''
            },
        },
		components: {
			ClientItemTemplate
		},
	}
</script>

<style lang="scss">
    #emailsHeader {
        .modal-dialog {
            max-width: 992px;
        }
        .modal-header{
            background: #032438;
            .modal-title, .close{
                color: #ffffff;
            }
        }
    }
    tr:hover {
        cursor: pointer;
    }
    td {
        vertical-align: middle !important;
    }
</style>